<script>
// import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  components: {

  },
  data() {
    return {
      rule: [],
      formValue: {},
    };
  },

  mounted() {
    this.getFormRule('dms_contract_form');
  },

  methods: {
    setRule(v) {
      const rowData = v;
      rowData.props = {
        ...rowData.props,
      };
      return rowData;
    },
    formComplete() {
      if (this.formConfig.code) {
        this.getData();
      }
    },
    getData() {
      this.setValue(this.formConfig);
    },
    submit() {
      if (this.getFormData()) {
        let path = '/dms/m/saleContract/addTuple';
        if (this.formConfig.code) {
          path = '/dms/m/saleContract/editTuple';
        }
        const params = this.getFormData();
        request.post(path, params).then((res) => {
          if (res.success) {
            if (this.formConfig.code) {
              this.$message.success('编辑成功');
            } else {
              this.$message.success('新增成功');
            }
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
</script>
